<template>
	<v-dialog v-model="show_bid_box" persistent width="auto" transition="slide-fade" fullscreen light>
		<v-row no-gutters class="bid" style="background: rgba(0, 0, 0, 0.4); height: 100vh">
			<v-col
				cols="12"
				no-gutters
				class="pl-6 d-flex align-center bid-box ma-0"
				style="height: 65px; border-bottom: 1px solid #e7eaf3; margin: 0px -24px 0px -24px"
			>
				<v-btn v-on:click="closeBidBox" class="my-back-btn" depressed>
					<img :src="$store.state.icons.icons['LeftArrow']" class="back-btn" alt />
					Back
				</v-btn>
			</v-col>
			<v-col cols="12" lg="6" class="bid-box pa-0 d-flex flex-column" style="border-right: 1px solid #e7eaf3">
				<div class="px-6 end-shadow" style="position: relative">
					<!-- <v-row no-gutters class="pl-6 d-flex align-center" style="height: 65px; border-bottom: 1px solid #e7eaf3; margin: 0px -24px 0px -24px">
						<v-btn v-on:click="show_bid_box = false" class="my-back-btn" depressed>
							<img :src="$store.state.icons.icons['LeftArrow']" class="back-btn" alt />
							Back
						</v-btn>
					</v-row> -->

					<v-row no-gutters class="py-6">
						<v-col cols="12" sm="6" class="d-flex align-center">
							<img v-if="delivery.category_id === 1" :src="$store.state.icons.icons['Lumber']" style="height: 36px" alt />
							<img v-if="delivery.category_id === 2" :src="$store.state.icons.icons['Electric']" style="height: 36px" alt />
							<img v-if="delivery.category_id === 3" :src="$store.state.icons.icons['Plumbing']" style="height: 36px" alt />
							<img v-if="delivery.category_id === 4" :src="$store.state.icons.icons['Masonry']" style="height: 36px" alt />
							<img v-if="delivery.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" style="height: 36px" alt />
							<img v-if="delivery.category_id === 6" :src="$store.state.icons.icons['Landscape']" style="height: 36px" alt />

							<div class="pl-7">
								<p class="heading-mf15">{{ delivery.list_name }}</p>
								<p class="heading-rf13">List ref: {{ delivery.list_id }}</p>
							</div>
						</v-col>
						<v-col cols="12" sm="6" class="mt-sm-0 mt-5 d-flex align-center justify-sm-end justify-space-between">
							<div v-if="delivery.status === 'IP' && delivery.pickup === 'Y'" class="d-flex align-center justify-center mr-2">
								<div class="d-flex align-center justify-center mr-4">
									<v-dialog
										content-class="email"
										v-model="dialog"
										persistent
										width="300"
										attach=".user-profile"
										transition="slide-fade"
										hide-overlay
										light
									>
										<template v-slot:activator="{ on, attrs }">
											<p class="heading-rf13" v-bind="attrs" v-on="on">Mark as delivered</p>
										</template>

										<v-card>
											<v-card-text> <div class="pt-5 text-center">Do you need copy of your conversation on email?</div> </v-card-text>

											<v-card-actions class="justify-space-around mx-10">
												<div class="cursor-pointer" plain color="#000" @click="dialog = false">No</div>

												<div class="cursor-pointer" plain color="#000" @click="closeChat">Yes</div>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</div>
								<div class="d-flex justify-center align-center">
									<div v-on:click="confirmDelivery()" class="delivery-check-box"></div>
								</div>
							</div>
							<div v-else-if="delivery.status === 'DP'">
								<button
									v-if="checkTime(delivery.date_of_delivery + ' ' + delivery.end_time)"
									class="text-tag-delivery-not text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
									@click="confirmDelivery()"
								>
									Mark as completed
								</button>
								<div v-else class="text-tag-delivery-not text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">
									To be delivered
								</div>
							</div>
							<div v-else-if="delivery.status === 'IP'" class="text-tag-blue d-flex align-center justify-center mr-2">
								<v-btn class="my-p-btn my_elevation" id="my_elevation" @click="dispatch()" depressed>Dispatch</v-btn>
							</div>
							<div v-else-if="delivery.status === 'D'" class="text-tag-delivery text-tag-green d-flex align-center justify-center mr-2">
								Delivery confirmed
							</div>
							<div v-else-if="delivery.status === 'R'" class="text-tag-reject text-tag-red d-flex align-center justify-center mr-2">
								Situation is being reviewed
								<br />by our customer service
							</div>
							<div>
								<v-tooltip left>
									<template v-slot:activator="{ on }">
										<a class="px-3" v-on:click="openMapRoot()">
											<img class="mt-2" :src="$store.state.icons.icons['DeliveryVan']" v-on="on" alt width="40" />
										</a>
									</template>
									<span>Click here for Map</span>
								</v-tooltip>
							</div>
							<div class="pl-2 pt-1" v-on:click="deliveryExport(delivery.delivery_id)" style="cursor: pointer">
								<v-tooltip left>
									<template v-slot:activator="{ on }">
										<img
											v-on="on"
											:src="$store.state.icons.icons['file-export']"
											style="
												height: 24px;
												filter: invert(61%) sepia(10%) saturate(1008%) hue-rotate(178deg) brightness(84%) contrast(84%);
											"
											alt
										/>
									</template>
									<span>Export delivery detail</span>
								</v-tooltip>
							</div>
						</v-col>
					</v-row>

					<!-- <v-row no-gutters class="map-box" style="height: 80px">
						<v-img :src="getMapSrc()" style="width: 100%; height: 80px; border-radius: 4px"></v-img>
					</v-row>
					<div class="d-flex align-center justify-center pb-5" style="margin-top: -60px">
						<div class="map-btn" v-on:click="openMapRoot()">
							<img :src="$store.state.icons.icons['DeliveryVan']" alt />
							Check on map
						</div>
					</div> -->
				</div>

				<!-- <div class="bid-items-content pa-0 px-6 pt-6"> -->
				<div class="px-6 pb-6 d-flex flex-column justify-space-between" style="flex: 1">
					<div>
						<app-list-interaction class="mb-6" v-if="delivery.notes != null" v-model="delivery.notes"></app-list-interaction>

						<v-row no-gutters class="display-time ma-0 px-0">
							<v-col cols="12" sm="4" class="pa-0 pt-4 d-flex">
								<div>
									<p class="heading-rf13 pb-sm-2 pb-0">Contact Person</p>
									<p class="heading-sf15">{{ this.$options.filters.capitalize(delivery.contact_name) }}</p>
									<p class="heading-rf15">{{ phoneFormat(delivery.phone) }}</p>
								</div>
							</v-col>
							<v-col cols="12" sm="4" class="pa-0 d-flex">
								<div v-if="delivery.address != null" class="pl-sm-6 mt-sm-0 mt-5">
									<p class="heading-rf13 pb-sm-2 pb-0">Address</p>
									<p class="heading-sf15">{{ firstAddress(delivery.address) }}</p>
									<p class="heading-rf15">{{ lastAddress(delivery.address) }}</p>
								</div>
							</v-col>
							<v-col cols="12" sm="4" class="pa-0 d-flex">
								<div class="pl-sm-6 mt-sm-0 mt-5">
									<p class="heading-rf13 pb-sm-2 pb-0">Delivery Deadline</p>
									<p class="heading-sf15">{{ delivery.date_of_delivery }}</p>
									<p class="heading-rf15">{{ timeOfDelivery() }}</p>
								</div>
							</v-col>
						</v-row>
					</div>
					<div class="bid-footer delivery-bid-total d-lg-flex d-none flex-column">
						<div class="bid-footer-layer"></div>
						<app-show-bid-total v-model="calculateTotal" class="ma-0 overflow-hidden"></app-show-bid-total>
						<div class="pt-4 d-flex">
							<p class="heading-sf18">Total:</p>
							<p class="heading-sf18 font-shadow ml-auto">{{ this.$options.filters.currency(total) }}</p>
						</div>
					</div>
				</div>
				<!-- <app-show-bid-total v-model="calculateTotal"></app-show-bid-total> -->
				<!-- </div> -->
			</v-col>
			<v-col cols="12" lg="6" style="background: #ffffff; margin-left: auto" class="bid-box bid-box-height pa-0 d-flex flex-column">
				<div class="bid-items-content px-6">
					<app-bid-items v-model="delivery" :listitems="listitems"></app-bid-items>
				</div>
			</v-col>
			<v-col class="d-lg-none d-block">
				<div class="bid-footer-layer"></div>
				<div class="bid-footer delivery-bid-total d-flex flex-column px-6">
					<app-show-bid-total v-model="calculateTotal" class="ma-0 overflow-hidden"></app-show-bid-total>
					<div class="pt-4 d-flex">
						<p class="heading-sf18">Total:</p>
						<p class="heading-sf18 font-shadow ml-auto">{{ this.$options.filters.currency(total) }}</p>
					</div>
				</div>
			</v-col>
		</v-row>
		<App-map-view-root v-bind:value="mapLatLong()" ref="openmaproot"></App-map-view-root>
		<app-confirm ref="confirm"></app-confirm>
	</v-dialog>
</template>

<script>
const BidItems = () => import('./BidItemComponent')
const ShowBidTotal = () => import('./ShowBidTotalComponent')
const Feedback = () => import('./FeedbackComponent')
const ListInteraction = () => import('./ListInteractionComponent')
const MapViewRoot = () => import('./MapViewRoot')
const Confirm = () => import('../../mycomponents/Confirm')
import { covLocalDate, covLocalTime, localDateFromDateTime, timeFormat } from '../../services/commonservice'
import { getDelivery, delivered, deliveryExport } from '../../services/deliveryservice'
import firebase from 'firebase/app'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../../config/variable'
import { AXIOS } from '../../plugins/axios'
import moment from 'moment'

export default {
	components: {
		AppBidItems: BidItems,
		AppShowBidTotal: ShowBidTotal,
		AppFeedback: Feedback,
		AppListInteraction: ListInteraction,
		AppMapViewRoot: MapViewRoot,
		AppConfirm: Confirm,
	},
	data() {
		return {
			dialog: false,
			bid_id: '',
			show_bid_box: false,
			delivery_data: {
				check: false,
				name: 'delivery',
				label: '',
			},
			delivery: {
				check_bid: true,
				biditems: [],
			},
			listitems: [],
			bid_total: {
				sub_total: 0.0,
				shipping: 0.0,
				service_fee: 0.0,
				sales_tax: 0.0,
				tax_percentage: 0.0,
			},
		}
	},

	computed: {
		calculateTotal() {
			this.bid_total.sub_total = 0
			this.bid_total.service_fee = 0
			this.bid_total.shipping = this.delivery.shipping
			this.bid_total.sales_tax = this.delivery.sales_tax
			this.delivery.biditems.forEach((item) => {
				this.bid_total.sub_total += Number(item.price) * Number(item.qty)
			})
			// this.bid_total.service_fee = ((this.bid_total.sub_total + this.bid_total.shipping) * 5) / 100
			this.delivery.sub_total = this.bid_total.sub_total
			this.delivery.service_fee = this.bid_total.service_fee
			this.bid_total.tax_percentage = this.delivery.tax_percentage
			return this.bid_total
		},

		total() {
			let total = Number(this.bid_total.sub_total + this.bid_total.shipping + this.bid_total.service_fee + this.bid_total.sales_tax)
			return parseFloat(total).toFixed(2)
		},
	},

	methods: {
		async closeChat() {},

		closeBidBox() {
			if (this.$route.query.data) {
				this.$router.push('/deliveries')
			}
			this.show_bid_box = false
		},

		dateFormat(data) {
			return localDateFromDateTime(data)
		},

		getMapSrc() {
			return this.delivery.url != undefined
				? this.delivery.url['list_header']
				: 'https://the-build-static-content.s3.us-east-2.amazonaws.com/icons/map-image.png'
		},

		phoneFormat(data) {
			return data != undefined ? this.delivery.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, '($1)-$2-$3') : ''
		},

		mapLatLong() {
			return {
				category_id: this.delivery.category_id,
				list_id: this.delivery.list_id,
				list_name: this.delivery.list_name,
				list_lat: this.delivery.list_lat,
				list_long: this.delivery.list_long,
				bid_lat: this.delivery.bid_lat,
				bid_long: this.delivery.bid_long,
				updated_at: this.delivery.list_updated_at,
				status: this.delivery.bid_status,
			}
		},

		openMapRoot() {
			this.$refs.openmaproot.openMapRoot()
		},

		deliverySubmit(id) {
			this.$store.commit('errorMessage', '')
			this.show_bid_box = true
			this.bid_id = id
			if (this.list_id === '') {
				this.show_bid_box = false
			}
			this.getDelivery()
		},

		firstAddress(address) {
			if (address) {
				let first_address = address.split(',')
				return first_address[0]
			}
		},

		lastAddress(address) {
			if (address) {
				let last_address = address.split(',')
				let full_address = last_address[1] != undefined ? last_address[1] : ''
				full_address = last_address[2] != undefined ? full_address + ', ' + last_address[2] : full_address
				full_address = last_address[3] != undefined ? full_address + ', ' + last_address[3] : full_address
				return full_address
			}
		},

		timeOfDelivery() {
			let st = this.delivery.start_time
			let et = this.delivery.end_time
			if (st != undefined && et != undefined) {
				return timeFormat(st) + ' - ' + timeFormat(et)
			}
		},

		requiredDeliveryTime() {
			let time = this.delivery.required_delivery_time

			if (time === 'M') {
				return 'Morning'
			} else if (time === 'N') {
				return 'Noon'
			} else if (time === 'A') {
				return 'Afternoon'
			} else if (time === 'L') {
				return 'Last Stop'
			} else if (time === 'AS') {
				return 'As soon as possible'
			}
		},

		async confirmDelivery() {
			if (await this.$refs.confirm.open('Delivery Dispatch Confirm', 'Are you sure the delivered material was received by the contractor?')) {
				if (await this.$refs.confirm.open('Chat Export', 'Do you need copy of your conversation on email?')) {
					let collection = await firebase.firestore().collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
					let collection_id = `${this.$store.state.auth.user_data.company_id}DC` + `${this.delivery.list_id}L`

					let listDistCompanyId = []
					let userCollection = await collection.where('is_active_chat', '==', true)
					userCollection.onSnapshot(async (doc) => {
						listDistCompanyId = []
						doc.forEach((doc) => {
							listDistCompanyId.push(doc.id)
						})
						if (listDistCompanyId && listDistCompanyId.includes(collection_id)) {
							let ref = collection.doc(collection_id).get()
							let chat = await ref.then((doc) => {
								return doc.data()
							})
							if (chat.is_active_chat) {
								await AXIOS.post('chat-close', { list_of_chat_room_id: [collection_id] })
								;(this.menuDialog = false), (this.dialog = false)
							}
						}
					})
				}
				this.delivery.status = 'D'
				let ob = {
					status: this.delivery.status,
				}
				let data = await delivered(this.delivery.delivery_id, ob)
				if (data.data) {
					this.$store.commit('delivered', {
						list_id: data.data.list_id,
						status: data.data.status,
					})
				}
				if (data.message) {
					this.show_bid_box = false
				}
			}
		},

		async dispatch() {
			this.delivery.status = 'DP'
			let ob = {
				status: this.delivery.status,
			}
			let data = await delivered(this.delivery.delivery_id, ob)
			if (data.data) {
				this.$store.commit('delivered', {
					list_id: data.data.list_id,
					status: data.data.status,
				})
			}

			if (data.message) {
				this.show_bid_box = false
			}
		},

		checkTime(date) {
			let delivery_date = moment(date).format('YYYY-MM-DD HH:mm:ss')
			let today = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
			if (moment(delivery_date).isBefore(today)) {
				return true
			} else {
				return false
			}
		},

		async deliveryExport(id) {
			let data = await deliveryExport(id)
			let url = data.data
			window.open(url, '_blank')
		},

		async getDelivery() {
			let data
			data = await getDelivery(this.bid_id)
			if (data?.error) {
				this.closeBidBox()
				this.$store.commit('showAlert', {
					show: true,
					message: 'Delivery details not found',
					type: 'error',
				})
			}
			data = data.data
			this.delivery.delivery_id = data.delivery_id
			this.delivery.status = data.status
			this.delivery.list_id = data.clist.list_id
			this.delivery.list_name = data.clist.list_name
			this.delivery.category_id = data.clist.category_id
			this.delivery.contact_name = data.contractor.fullname
			this.delivery.phone = data.contractor.phone
			this.delivery.pickup = data.clist.pickup
			this.delivery.address = data.clist.address
			this.delivery.date_of_delivery = covLocalDate(data.bid.date_of_delivery, data.bid.start_time)
			this.delivery.time_of_delivery = data.bid.time_of_delivery
			this.delivery.start_time = covLocalTime(data.bid.date_of_delivery, data.bid.start_time)
			this.delivery.end_time = covLocalTime(data.bid.date_of_delivery, data.bid.end_time)
			this.delivery.sub_total = data.bid.sub_total
			this.delivery.shipping = data.bid.shipping
			this.delivery.service_fee = data.bid.service_fee
			this.delivery.sales_tax = data.bid.sales_tax
			this.delivery.tax_percentage = data.bid.tax_percentage
			this.delivery.notes = data.clist.notes
			this.delivery.biditems = data.bid.biditems
			this.listitems = data.clist.listitems
			this.delivery.list_lat = data.clist.latitude
			this.delivery.list_long = data.clist.longitude
			this.delivery.bid_lat = data.bid.latitude
			this.delivery.bid_long = data.bid.longitude
			this.delivery.url = data.clist.url
		},
	},
}
</script>

<style lang="scss"></style>
